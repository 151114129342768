import { useLocalStorage } from "react-use"
import { useState, useEffect } from "react"
import { ProductGroups } from "../data/ProductGroups"

export interface User {
  id: string
  name: string
  groups: ProductGroups[]
  new: boolean
  year: string
  languages: string[]
  active: null | string
  expires: null | string
}

export interface ResponseInterface {
  user: User | undefined
  login: (user: User) => void
  logout: () => void
}

export const useUser = (): ResponseInterface => {
  const [user, setUser, removeUser] = useLocalStorage<User>(
    "kuhn-user_v22",
    undefined
  )
  const [userCache, setUserCache] = useState(user)

  useEffect(() => {
    setUserCache(user)
  }, [user])

  return {
    user: userCache,
    login: user => {
      setUser(user)
      setUserCache(user)
    },
    logout: removeUser,
  }
}
