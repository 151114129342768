/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Header } from "./header"
import "./layout.css"
import { FC } from "react"
import { useUser } from "../hooks/useUser"

export const Layout: FC = ({ children }) => {

  const {user, logout} = useUser();
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const handleLogout = () => {
    logout();
    window.location.href = "/";
  }

  return (
    <div className="wrapper">
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main className="page-main">
        <div className="content-container" style={{position: "relative"}}>
          <div>
          { user && (
            <div style={{position: "absolute", top: -40, right: 0}}>
              <strong>Benutzer:</strong> {user?.id} &nbsp;
              <button onClick={handleLogout}>Abmelden</button>
            </div>
          )}
          </div>
          {children}
        </div>
      </main>
      <footer className="page-footer">
        <p>KUHN CENTER AUSTRIA GmbH - Hafnerstraße 1 - A-4702 Wallern a. d. Trattnach<br />
          Telefon:43 (0) 7249 – 42 240 – 0 - Telefax: + 43 (0) 7249 – 42 240 – 40 - Mail : <a href="mailto:office@kuhncenter.at" className="mail">office@kuhncenter.at </a></p>
      </footer>
    </div>
  )
}
