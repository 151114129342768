import { Link } from "gatsby"
import React, { FC } from "react"

interface HeaderProps {
  siteTitle: string
}

export const Header: FC<HeaderProps> = () => (
  <header className="page-header">
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `0rem 1rem 0 1rem`,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/">
          <img src="/images/logo.png" alt="Logo"/>
        </Link>
      </h1>
    </div>
  </header>
)
